@import '~antd/dist/antd.css';

@font-face {
    font-family: 'SamsungSharpSansBold';
    src: url('../assets/fonts/SamsungSharpSans-Bold.woff2') format('woff2'),
        url('../assets/fonts/SamsungSharpSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SamsungOne';
    src: url('../assets/fonts/SamsungOne-700.woff2') format('woff2'),
        url('../assets/fonts/SamsungOne-700.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SamsungOne';
    src: url('../assets/fonts/SamsungOne-400.woff2') format('woff2'),
        url('../assets/fonts/SamsungOne-400.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'iCielSamsungSharpSans';
    src: url('../assets/fonts/iCielSamsungSharpSans-Bold.woff2') format('woff2'),
        url('../assets/fonts/iCielSamsungSharpSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'iCielSamsungSharpSans';
    src: url('../assets/fonts/iCielSamsungSharpSans-Medium.woff2') format('woff2'),
        url('../assets/fonts/iCielSamsungSharpSans-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
        url('../assets/fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
        url('../assets/fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
  outline: none;
}

body.lang1{
  font-family: SamsungOne;
}

body.lang2{
  font-family: Roboto;
}

body.lang3{
  font-family: iCielSamsungSharpSans;
}

.lang1 h3, .lang1 button, .lang1 button span{
	font-family: SamsungSharpSansBold;
	font-weight: bold;
}

.lang2 h3, .lang2 button, .lang2 button span{
	font-family: Roboto;
	font-weight: bold;
}

.lang3 h3, .lang3 button, .lang3 button span{
	font-family: iCielSamsungSharpSans;
	font-weight: bold;
}

.lang1 .samsung_sands_b{
  font-family: SamsungSharpSansBold;
  font-weight: bold;
}

.lang2 .samsung_sands_b{
  font-family: Roboto;
  font-weight: bold;
}

.lang3 .samsung_sands_b{
  font-family: iCielSamsungSharpSans;
  font-weight: bold;
}

h1,h2,h3,h4,h5,p {
  white-space: pre-wrap;
}
